<template>
  <v-container class="my-0 pa-0" fluid>
    <v-card>
      <div class="card-header-padding pt-10">
        <v-row>
          <v-col>
            <h5 class="font-weight-bold text-h5 text-typo mb-0">
              Шалгалтын дүн
            </h5></v-col
          >

          <!-- <v-col class="text-end">
            <v-btn
              class="btn bg-gradient-danger"
              dark
              @click="scoreDialogSelected = !scoreDialogSelected"
            >
              uneleh+
            </v-btn>

            <v-btn class="btn bg-gradient-danger" dark @click="addShalgalt">
              shagalt+
            </v-btn>
          </v-col> -->
        </v-row>
        <v-row class="mt-0">
          <v-col>
            <p class="text-sm text-body mb-0">
              Шалгалтын дүнг ЭСИС дээр оруулна. Xарин үүнийг энэ систем дээр
              оруулвал бид эцэг, эx, сурагчдад xаруулж таны дүн танилцуулаx
              ажлыг xөнгөвчлөнө. Эцэг эx, сурагч сурагчийн имэйл болон төрсөн
              огноог оруулж системээс дүнгээ xарна.
            </p>
            <p class="blue--text" v-if="filteredStudents">
              Сурагчдын тоо:
              <span class="font-weight-bold"
                >{{ filteredStudents.length }}
              </span>
            </p>
          </v-col>
        </v-row>
        <v-alert
          color="#F8BBD0"
          class="pt-4"
          text
          prominent
          icon="mdi-check-circle-outline"
          >6 сарын 10 -нд нээгдэнэ.
          <div style="color: #d81b60; font-weight: normal">
            Шалгалтын дүнг ЭСИС дээр оруулна. Xарин үүнийг энэ систем дээр
              оруулвал бид эцэг, эx, сурагчдад xаруулж таны дүн танилцуулаx
              ажлыг xөнгөвчлөнө. Эцэг эx, сурагч сурагчийн имэйл болон төрсөн
              огноог оруулж системээс дүнгээ xарна.
          </div>
        </v-alert>
      </div>
      <v-progress-linear
        v-if="loading"
        color="red"
        height="6"
        indeterminate
      ></v-progress-linear>
      <v-data-table
        v-if="filteredStudents"
        :headers="headers2"
        :items="filteredStudents"
        :search="search"
        hide-details
        hide-default-footer
        :items-per-page="-1"
      >
        <v-alert slot="no-results" :value="true" color="error" icon="warning">
          Your search for "{{ search }}" found no results.
        </v-alert>
      </v-data-table>
      <!-- <v-data-table
        :headers="headers"
        :items="items"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :loading="loading"
        :no-data-text="'No data available'"
        @custom-sort="onCustomSort"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Data Table</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-toolbar>
        </template>
      </v-data-table> -->
    </v-card>

    <v-dialog
      v-model="scoreDialogSelected"
      width="1000"
      v-if="scoreDialogSelected"
      persistent
      scrollable
    >
      <v-card class="py-4 scoreTables">
        <div class="card-header-padding pb-0">
          <v-row>
            <v-col>
              <h5 class="font-weight-bold text-h5 text-typo mb-0 ml-4">
                шалгалт {{ filteredStudents.length }}
              </h5>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </div>
        <FinalExaminationDialog
          class="pt-0"
          v-if="filteredStudents && filteredStudents.length > 0"
          v-bind="$attrs"
          :students="filteredStudents"
          :scores="[]"
        >
        </FinalExaminationDialog>
        <h3 v-else>daAAA</h3>
        <v-card-text
          class="mx-10 py-10 mt-4 px-4 text-center"
          style="width: 80%; overflow: hidden"
          v-if="savingUnelgee"
        >
          <p style="display: block !important" class="red--text">
            Дүн хадгалж байна. Түр хүлээнэ үү!
          </p>
          <v-progress-linear
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </v-card-text>
        <v-card-actions class="mx-6 py-4 mt-4" v-else>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            class="white--text"
            @click="_closeScoringDialog()"
          >
            Цуцлах
          </v-btn>
          <v-btn
            @click="_closeScoringDialog()"
            class="bg-gradient-success white--text ml-2 mr-3 text-capitalize"
          >
            Хадгалах
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
//   const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
import FinalExaminationDialog from "./FinalExaminationDialog.vue";
export default {
  components: {
    FinalExaminationDialog,
  },
  data: () => ({
    loading: false,
    savingUnelgee: false,
    scoreDialogSelected: false,
    sortBy: "name",
    sortDesc: false,
    search: "",
    headers: [
      { text: "Name", value: "name", sortable: true },
      { text: "Age", value: "age", sortable: true },
      { text: "Location", value: "location", sortable: true },
    ],
    items: [
      { name: "John", age: 30, location: "New York" },
      { name: "Alice", age: 25, location: "London" },
      { name: "Bob", age: 40, location: "Paris" },
    ],
    myStudents: null,

    headerNames: [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },

      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "FIRST_NAME",
        fixed: true,
      },
      {
        text: "Төрсөн огноо",
        align: "start",
        sortable: true,
        value: "DATE_OF_BIRTH",
        width: "10%",
      },

      {
        text: "Үйлдэл",
        value: "actions",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
    ],
  }),
  props: {
    zSchool: {
      type: Object,
    },
    zClassGroup: {
      type: Object,
    },
  },
  computed: {
    ...mapState(["userData", "currentUser"]),
    ...sync("*"),
    headers2() {
      return this.headerNames;
    },
    filteredStudents() {
      var list = [];
      if (this.myStudents != null) {
        var counter = 0;
        for (var item of this.myStudents) {
          item.avatar = require("@/assets/img/team-2.jpg");
          if (!item.moved) {
            counter++;
            item.index = counter;
            list.push(item);
          }
        }
      }
      return list;
    },
  },
  created() {
    if (!this.school) {
      this.school = this.userData.school;
    } else {
      this.school = this.zSchool;
    }
    this.currentClassGroup = this.zClassGroup;
    this.school.ref
      .collection("students-" + this.school.currentYear)
      .orderBy("firstName", "asc")
      .where(
        "STUDENT_GROUP_ID",
        "==",
        this.currentClassGroup["STUDENT_GROUP_ID"]
      )
      .onSnapshot((querySnapshot) => {
        this.myStudents = [];
        var mCounter = 0;
        querySnapshot.forEach((doc) => {
          mCounter++;
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          console.log(mCounter, item);
          item["DATE_OF_BIRTH"] = item["DATE_OF_BIRTH"]
            ? item["DATE_OF_BIRTH"].replace("T00:00:00.000Z", "")
            : null;
          if (item.moved != true) this.myStudents.push(item);
        });
      });
  },
  methods: {
    addShalgalt() {},

    _closeScoringDialog() {
      this.scoreDialogSelected = !this.scoreDialogSelected;
    },
    _saveScoringDialog() {
      this._closeScoringDialog();
    },
    onCustomSort(event) {
      const { sortBy, sortDesc } = event;
      // Implement your custom sorting logic here
      // For example, you can use Array.prototype.sort()
      this.items.sort((a, b) => {
        if (sortDesc) {
          return a[sortBy] > b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        }
      });
    },
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
    formatDate2(val) {
      if (!val) {
        return "-";
      }
      let date = new Date(val);
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>
